import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.airtable;
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.data.title} description={post.data.title} meta={[
          {
            property: 'og:image',
            content: post.data.image
          },
          {
            name: 'twitter:image',
            content: post.data.image
          },
        ]} />
        <h1>{post.data.title}</h1>
        <p
          style={{
            ...scale(-1 / 5),
            display: 'block',
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
          }}
        >
          {post.data.date}
        </p>
        <div 
            dangerouslySetInnerHTML={{ 
              __html: post.data.post_markdown.childMarkdownRemark.html 
            }} 
        />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <Bio />

        <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            listStyle: 'none',
            padding: 0,
          }}
        >
          <li>
            {
              previous &&
              <Link to={previous.data.slug} rel="prev">
                ← {previous.data.title}
              </Link>
            }
          </li>
          <li>
            {
              next &&
              <Link to={next.data.slug} rel="next">
                {next.data.title} →
              </Link>
            }
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String) {
    site {
      siteMetadata {
        title
        author
      }
    }
    airtable(data: { slug: { eq: $slug } }) {
      data {
        slug
        title
        author
        post_markdown {
          childMarkdownRemark {
            html
          }
        }
        date(formatString: "MMMM DD, YYYY")
        image
      }
    }
  }
`
